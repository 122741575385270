// http://ionicframework.com/docs/theming/
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700");

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";
@import "@ionic/angular/css/ionic-swiper";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.errorAlert {
    .alert-wrapper {
      .alert-head {
          // header styling here eg. background
          h2 {
              color: var(--ion-color-danger)
          }
      }
    }
  }


  .successAlert {
    .alert-wrapper {
      .alert-head {
          // header styling here eg. background
          h2 {
              color: var(--ion-color-success)
          }
      }
    }
  }

  .auth-btn {
    margin: calc(var(--page-margin) / 2) 0px;
    text-align: center;
   }

   .submit-btn {
    margin: var(--page-margin);
  }

   .sc-ion-alert-md-h{
     --min-width: 424px;
   }

  .alert-checkbox-label.sc-ion-alert-md {
    overflow: visible;
    white-space: inherit;
    text-align-last: justify;
  }

  .header-title {
    text-transform: uppercase;
    font-size: 16px;
    color: var(--ion-color-secondary);
  }

  th,td {
    text-align: center !important;
    word-wrap: break-word;
    padding: 13px !important;
  } 

  .scroll-down{
    overflow-x: auto;
  }

  .mat-sort-header-arrow{
    color: #FFFFFF;
  }

  .mat-sort-header-container{
    display: inline-flex !important;
  }

  .mat-mdc-checkbox{
    width: 18px;
    height: 18px;
    accent-color: #4C6DA7;
  }

  .new-btn{
    border-radius: 100%;
    align-self: center;
  }


  .action-btn {
    --background: transparent;
    --box-shadow: none !important;
    --color: black;
    font-size: small;
    border-radius: 100%; /* Makes the button round */
  }

  button[mat-icon-button] {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .mat-mdc-menu-panel {
    padding: 8px;
  }
  
  .mat-mdc-menu-item {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  
  .mat-mdc-menu-item:hover {
    background-color: #f2f2f2;
  }
  
  .mat-mdc-menu-item span {
    margin-left: 8px;
  }

  @media only screen and (min-width: 768px) {

    .desktop-border{
      margin-top: 40px;
      padding: 20px;
      border: groove;
      border-width: 5px;
      border-radius: 10px;
      position: absolute;
      left: 50%;
      transform: translate(-50%,-0%);
    }

    .inputs-list {
      .input-item{
        display: flex;
        justify-content: center;
      }
    }

    .auth-btn {
      display: grid;
      justify-content: center;
    }

    .submit-btn {
      display: grid;
      justify-content: center;
    }

    .error-container {
      .error-message {justify-content: center;}
    }

    .other-auth-options-row {
      justify-content: center;
    }


    //List Pages
    .table-container {
      max-width: 90%;
      margin: 0 auto;
      background-color: #f2f2f2;
    }
    
    table {
      border-radius: 4px;
      border-collapse: collapse;
    }
    
    th, td {
      padding: 12px 15px ;
    }
    
    th {
      background-color: #4c6da7 !important;
      color: #fff !important;
      font-weight: bold;
    }
    
    td {
      background-color: #fff;
    }

    tr:hover {
      cursor: pointer;
      background-color: #f2f2f2;
      text-decoration: underline;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
    mat-paginator {
      background-color: #f2f2f2;
      font-size: 14px;
    }

    .scroll-down{
      position: relative;
      max-height: 400px;
      overflow: auto;
    }

    ion-card {
      width: 50%;
      justify-self: center;
      margin-top: 30px;

      .search{
        width: 98%;
        padding-left: 0;
      }
    }
    
  }
